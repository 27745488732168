<template>
  <b-card-code title="Ajouter un produit">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de produit </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="produit"
            >
              <validation-provider
                #default="{ errors }"
                name="Produit"
                rules="required"
              >
                <b-form-input
                  v-model="produit.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Produit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Référence"
            >
              <validation-provider
                #default="{ errors }"
                name="reference"
                rules="required"
              >
                <b-form-input
                  v-model="produit.reference"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Référence"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Stock Bioherbs"
            >
              <validation-provider
                #default="{ errors }"
                name="Stock"
                rules="required|numeric"
              >
                <b-form-input
                  v-model="produit.quantity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="stock"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Stock labo"
            >
              <validation-provider
                #default="{ errors }"
                name="Stock labo"
                rules="required|numeric"
              >
                <b-form-input
                  v-model="produit.quantity_labo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="stock labo"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Stock Alert"
            >
              <validation-provider
                #default="{ errors }"
                name="Stock Alert"
                rules="required|numeric"
              >
                <b-form-input
                  v-model="produit.stock_alert"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Stock Alert"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Prix de vente"
            >
              <validation-provider
                #default="{ errors }"
                name="sales_price"
                rules="required|numeric"
              >
                <b-form-input
                  v-model="produit.sales_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="prix de vente"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Prix d'achat"
            >
              <validation-provider
                #default="{ errors }"
                name="purchase_price"
              >
                <b-form-input
                  v-model="produit.purchase_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix de vente"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prix de gros"
            >
              <validation-provider
                #default="{ errors }"
                name="wholesale_price"
                rules="numeric"
              >
                <b-form-input
                  v-model="produit.wholesale_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix de gros"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prix de dépôt"
            >
              <validation-provider
                #default="{ errors }"
                name="repository_price"
                rules="numeric"
              >
                <b-form-input
                  v-model="produit.repository_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix de dépôt"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Description"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
              >
                <b-form-textarea
                  v-model="produit.description"
                  placeholder="Textarea"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addProduit"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
// import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      produit: {
        reference: '',
        name: '',
        repository_price: '',
        wholesale_price: '',
        purchase_price: '',
        sales_price: '',
        description: '',
        quantity: '',
        stock_alert: '',
      },
      required,
    }
  },

  methods: {
    async addProduit() {
      await axios
        .post(
          '/products/create/',
          {
            reference: this.produit.reference,
            name: this.produit.name,
            wholesale_price: this.produit.wholesale_price,
            wholesale_price_ttc: ((this.produit.wholesale_price) * (1 + (19 / 100))).toFixed(3),
            purchase_price: this.produit.purchase_price,
            sales_price: this.produit.sales_price,
            sales_price_ttc: ((this.produit.sales_price) * (1 + (19 / 100))).toFixed(3),
            description: this.produit.description,
            quantity: this.produit.quantity,
            stock_alert: this.produit.stock_alert,
            quantity_labo: this.produit.quantity_labo,
            quantity_total: Number(this.produit.quantity_labo) + Number(this.produit.quantity),
            barcode: this.produit.barcode,
            repository_price: this.produit.repository_price,
            repository_price_ttc: ((this.produit.repository_price) * (1 + (19 / 100))).toFixed(3),
          },
        )
        .then(() => {
          this.$router.push('/produits/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'produit ajoutée avec succés')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
