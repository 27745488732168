var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter un produit"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de produit ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"produit"}},[_c('validation-provider',{attrs:{"name":"Produit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Produit"},model:{value:(_vm.produit.name),callback:function ($$v) {_vm.$set(_vm.produit, "name", $$v)},expression:"produit.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Référence"}},[_c('validation-provider',{attrs:{"name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Référence"},model:{value:(_vm.produit.reference),callback:function ($$v) {_vm.$set(_vm.produit, "reference", $$v)},expression:"produit.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Stock Bioherbs"}},[_c('validation-provider',{attrs:{"name":"Stock","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"stock","type":"number"},model:{value:(_vm.produit.quantity),callback:function ($$v) {_vm.$set(_vm.produit, "quantity", $$v)},expression:"produit.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Stock labo"}},[_c('validation-provider',{attrs:{"name":"Stock labo","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"stock labo","type":"number"},model:{value:(_vm.produit.quantity_labo),callback:function ($$v) {_vm.$set(_vm.produit, "quantity_labo", $$v)},expression:"produit.quantity_labo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Stock Alert"}},[_c('validation-provider',{attrs:{"name":"Stock Alert","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Stock Alert","type":"number"},model:{value:(_vm.produit.stock_alert),callback:function ($$v) {_vm.$set(_vm.produit, "stock_alert", $$v)},expression:"produit.stock_alert"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prix de vente"}},[_c('validation-provider',{attrs:{"name":"sales_price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"prix de vente","type":"number"},model:{value:(_vm.produit.sales_price),callback:function ($$v) {_vm.$set(_vm.produit, "sales_price", $$v)},expression:"produit.sales_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prix d'achat"}},[_c('validation-provider',{attrs:{"name":"purchase_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix de vente","type":"number"},model:{value:(_vm.produit.purchase_price),callback:function ($$v) {_vm.$set(_vm.produit, "purchase_price", $$v)},expression:"produit.purchase_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prix de gros"}},[_c('validation-provider',{attrs:{"name":"wholesale_price","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix de gros","type":"number"},model:{value:(_vm.produit.wholesale_price),callback:function ($$v) {_vm.$set(_vm.produit, "wholesale_price", $$v)},expression:"produit.wholesale_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prix de dépôt"}},[_c('validation-provider',{attrs:{"name":"repository_price","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix de dépôt","type":"number"},model:{value:(_vm.produit.repository_price),callback:function ($$v) {_vm.$set(_vm.produit, "repository_price", $$v)},expression:"produit.repository_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Textarea","rows":"3"},model:{value:(_vm.produit.description),callback:function ($$v) {_vm.$set(_vm.produit, "description", $$v)},expression:"produit.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addProduit.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }